import axios from 'axios'

export default class MajorService {
	
	getUpdateInfo() {
		return axios.get('data/update_info.json').then(res => res.data);
	}

	getGISClass() {
		return axios.get('data/20220614标注用要素代码定义.json').then(res => res.data.data);
		// return axios.get('data/20230404分词典.json').then(res => res.data.data);
		// return axios.get('data/20230422分词典.json').then(res => res.data.data);
	}

	getHelperText() {
		return axios.get('data/20220628帮助文档.json').then(res => res.data);
	}
	
	getTripleData() {
		return axios.get('data/道路图谱与节点.json').then(res => res.data);
	}
}
