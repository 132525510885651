<template>
	<div v-if='is_processing' style="display: flex;justify-content:center;">
		<div class='card processing-container p-shadow-8' style="top:50;">
			<p style="fontSize: 1.3rem;">操作处理中...
				<i class="pi pi-spin pi-spinner"
					style="fontSize: 2rem;float: left;margin-right: 20px;margin-left: -20px;"></i>
				<i class="pi pi-times close-processing" @click='closeProcessing' title='关闭'></i>
			</p>
		</div>
	</div>
<!-- 	<div v-if='!is_login'>
		<Login @flushLoginStatus='flushLoginStatus' @goWechatLogin='goWechatLogin'></Login>
	</div> -->
	<div :class="containerClass" @click="onWrapperClick">
		<AppTopBar @toggleSetting='toggleSetting' @menu-toggle="onMenuToggle" />
		<div class="layout-sidebar" @click="onSidebarClick">
			<AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
		</div>

		<div class="layout-main-container">
			<div class="layout-main">
				<router-view />
			</div>
			<!-- <AppFooter /> -->
		</div>

		<AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" />
		<transition name="layout-mask">
			<div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
		</transition>
	</div>
</template>

<script>
	import AppTopBar from './AppTopbar.vue';
	import AppMenu from './AppMenu.vue';
	import AppConfig from './AppConfig.vue';
	// import Login from './pages/Login.vue';

	// import AppFooter from './AppFooter.vue';

	export default {
		data() {
			return {
				layoutMode: 'overlay',
				staticMenuInactive: false,
				overlayMenuActive: false,
				mobileMenuActive: false,
				is_login: false,
				menu: [{
					label: 'PDF批注',
					items: [{
							label: 'pdfjs-annotation',
							icon: 'pi pi-fw pi-pencil',
							to: '/pdfjs-annotation'
						},
						{
							label: 'pdfjs-cors',
							icon: 'pi pi-fw pi-book',
							to: '/pdfjs-cors'
						}
					]
				}],
				// menu: [{
				// 		label: '本体构建',
				// 		items: [{
				// 			label: '实体关系标注',
				// 			icon: 'pi pi-fw pi-pencil',
				// 			to: '/'
				// 		}]
				// 	},
				// 	{
				// 		label: '可视化',
				// 		items: [{
				// 			label: 'XML可视化',
				// 			icon: 'pi pi-fw pi-home',
				// 			to: '/xmlvisualization'
				// 		}]
				// 	},
				// 	{
				// 		label: 'Home',
				// 		items: [{
				// 			label: 'Dashboard',
				// 			icon: 'pi pi-fw pi-home',
				// 			to: '/dashboard'
				// 		}]
				// 	},
				// 	{
				// 		label: 'UI Components',
				// 		icon: 'pi pi-fw pi-sitemap',
				// 		items: [{
				// 				label: 'Form Layout',
				// 				icon: 'pi pi-fw pi-id-card',
				// 				to: '/formlayout'
				// 			},
				// 			{
				// 				label: 'Input',
				// 				icon: 'pi pi-fw pi-check-square',
				// 				to: '/input'
				// 			},
				// 			{
				// 				label: "Float Label",
				// 				icon: "pi pi-fw pi-bookmark",
				// 				to: "/floatlabel"
				// 			},
				// 			{
				// 				label: "Invalid State",
				// 				icon: "pi pi-fw pi-exclamation-circle",
				// 				to: "/invalidstate"
				// 			},
				// 			{
				// 				label: 'Button',
				// 				icon: 'pi pi-fw pi-mobile',
				// 				to: '/button'
				// 			},
				// 			{
				// 				label: 'Table',
				// 				icon: 'pi pi-fw pi-table',
				// 				to: '/table'
				// 			},
				// 			{
				// 				label: 'List',
				// 				icon: 'pi pi-fw pi-list',
				// 				to: '/list'
				// 			},
				// 			{
				// 				label: 'Tree',
				// 				icon: 'pi pi-fw pi-share-alt',
				// 				to: '/tree'
				// 			},
				// 			{
				// 				label: 'Panel',
				// 				icon: 'pi pi-fw pi-tablet',
				// 				to: '/panel'
				// 			},
				// 			{
				// 				label: 'Overlay',
				// 				icon: 'pi pi-fw pi-clone',
				// 				to: '/overlay'
				// 			},
				// 			{
				// 				label: "Media",
				// 				icon: "pi pi-fw pi-image",
				// 				to: "/media"
				// 			},
				// 			{
				// 				label: 'Menu',
				// 				icon: 'pi pi-fw pi-bars',
				// 				to: '/menu'
				// 			},
				// 			{
				// 				label: 'Message',
				// 				icon: 'pi pi-fw pi-comment',
				// 				to: '/messages'
				// 			},
				// 			{
				// 				label: 'File',
				// 				icon: 'pi pi-fw pi-file',
				// 				to: '/file'
				// 			},
				// 			{
				// 				label: 'Chart',
				// 				icon: 'pi pi-fw pi-chart-bar',
				// 				to: '/chart'
				// 			},
				// 			{
				// 				label: 'Misc',
				// 				icon: 'pi pi-fw pi-circle-off',
				// 				to: '/misc'
				// 			},
				// 		]
				// 	},
				// 	{
				// 		label: 'UI Blocks',
				// 		items: [{
				// 				label: 'Free Blocks',
				// 				icon: 'pi pi-fw pi-eye',
				// 				to: '/blocks',
				// 				badge: "NEW"
				// 			},
				// 			{
				// 				label: 'All Blocks',
				// 				icon: 'pi pi-fw pi-globe',
				// 				url: 'https://www.primefaces.org/primeblocks-vue',
				// 				target: '_blank'
				// 			}
				// 		]
				// 	},
				// 	{
				// 		label: 'Icons',
				// 		items: [{
				// 			label: 'PrimeIcons',
				// 			icon: 'pi pi-fw pi-prime',
				// 			to: '/icons'
				// 		}]
				// 	},
				// 	{
				// 		label: 'Pages',
				// 		icon: 'pi pi-fw pi-clone',
				// 		items: [{
				// 				label: 'Crud',
				// 				icon: 'pi pi-fw pi-user-edit',
				// 				to: '/crud'
				// 			},
				// 			{
				// 				label: 'Timeline',
				// 				icon: 'pi pi-fw pi-calendar',
				// 				to: '/timeline'
				// 			},
				// 			{
				// 				label: 'Empty',
				// 				icon: 'pi pi-fw pi-circle-off',
				// 				to: '/empty'
				// 			},
				// 		]
				// 	},
				// 	{
				// 		label: 'Menu Hierarchy',
				// 		icon: 'pi pi-fw pi-search',
				// 		items: [{
				// 				label: 'Submenu 1',
				// 				icon: 'pi pi-fw pi-bookmark',
				// 				items: [{
				// 						label: 'Submenu 1.1',
				// 						icon: 'pi pi-fw pi-bookmark',
				// 						items: [{
				// 								label: 'Submenu 1.1.1',
				// 								icon: 'pi pi-fw pi-bookmark'
				// 							},
				// 							{
				// 								label: 'Submenu 1.1.2',
				// 								icon: 'pi pi-fw pi-bookmark'
				// 							},
				// 							{
				// 								label: 'Submenu 1.1.3',
				// 								icon: 'pi pi-fw pi-bookmark'
				// 							},
				// 						]
				// 					},
				// 					{
				// 						label: 'Submenu 1.2',
				// 						icon: 'pi pi-fw pi-bookmark',
				// 						items: [{
				// 								label: 'Submenu 1.2.1',
				// 								icon: 'pi pi-fw pi-bookmark'
				// 							},
				// 							{
				// 								label: 'Submenu 1.2.2',
				// 								icon: 'pi pi-fw pi-bookmark'
				// 							}
				// 						]
				// 					},
				// 				]
				// 			},
				// 			{
				// 				label: 'Submenu 2',
				// 				icon: 'pi pi-fw pi-bookmark',
				// 				items: [{
				// 						label: 'Submenu 2.1',
				// 						icon: 'pi pi-fw pi-bookmark',
				// 						items: [{
				// 								label: 'Submenu 2.1.1',
				// 								icon: 'pi pi-fw pi-bookmark'
				// 							},
				// 							{
				// 								label: 'Submenu 2.1.2',
				// 								icon: 'pi pi-fw pi-bookmark'
				// 							},
				// 							{
				// 								label: 'Submenu 2.1.3',
				// 								icon: 'pi pi-fw pi-bookmark'
				// 							},
				// 						]
				// 					},
				// 					{
				// 						label: 'Submenu 2.2',
				// 						icon: 'pi pi-fw pi-bookmark',
				// 						items: [{
				// 								label: 'Submenu 2.2.1',
				// 								icon: 'pi pi-fw pi-bookmark'
				// 							},
				// 							{
				// 								label: 'Submenu 2.2.2',
				// 								icon: 'pi pi-fw pi-bookmark'
				// 							}
				// 						]
				// 					}
				// 				]
				// 			}
				// 		]
				// 	},
				// 	{
				// 		label: 'Get Started',
				// 		items: [{
				// 				label: 'Documentation',
				// 				icon: 'pi pi-fw pi-question',
				// 				command: () => {
				// 					window.location = "#/documentation"
				// 				}
				// 			},
				// 			{
				// 				label: 'View Source',
				// 				icon: 'pi pi-fw pi-search',
				// 				command: () => {
				// 					window.location = "https://github.com/primefaces/sakai-vue"
				// 				}
				// 			}
				// 		]
				// 	}
				// ]
			}
		},
		watch: {
			$route() {
				this.menuActive = false;
				this.$toast.removeAllGroups();
			}
		},
		mounted() {
			//打开之后当即跳转页面
			// this.$router.push({
			// 	path: '/kgsearch',
			// })
			
			if (location.href.includes('pdfjs-cors')){
				this.$router.push({
					path: '/pdfjs-cors',
				})
			}else{
				this.$router.push({
					path: '/pdfjs-annotation',
				})
			}

			
			// this.flushLoginStatus();
		},
		methods: {
			flushLoginStatus() {
				this.is_login = this.$appState.is_login;
				this.$router.push({
					path: '/pdfjs-annotation',
				})
				// this.$router.push({
				// 	path: '/kgsearch',
				// })
				// this.$router.push({
				// 	path: '/kgevaluate',
				// })
			},
			onWrapperClick() {
				if (!this.menuClick) {
					this.overlayMenuActive = false;
					this.mobileMenuActive = false;
				}

				this.menuClick = false;
			},
			onMenuToggle() {
				this.menuClick = true;

				if (this.isDesktop()) {
					if (this.layoutMode === 'overlay') {
						if (this.mobileMenuActive === true) {
							this.overlayMenuActive = true;
						}

						this.overlayMenuActive = !this.overlayMenuActive;
						this.mobileMenuActive = false;
					} else if (this.layoutMode === 'static') {
						this.staticMenuInactive = !this.staticMenuInactive;
					}
				} else {
					this.mobileMenuActive = !this.mobileMenuActive;
				}

				event.preventDefault();
			},
			onSidebarClick() {
				this.menuClick = true;
			},
			onMenuItemClick(event) {
				if (event.item && !event.item.items) {
					this.overlayMenuActive = false;
					this.mobileMenuActive = false;
				}
			},
			onLayoutChange(layoutMode) {
				this.layoutMode = layoutMode;
			},
			addClass(element, className) {
				if (element.classList)
					element.classList.add(className);
				else
					element.className += ' ' + className;
			},
			removeClass(element, className) {
				if (element.classList)
					element.classList.remove(className);
				else
					element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') +
						'(\\b|$)', 'gi'), ' ');
			},
			isDesktop() {
				return window.innerWidth >= 992;
			},
			isSidebarVisible() {
				if (this.isDesktop()) {
					if (this.layoutMode === 'static')
						return !this.staticMenuInactive;
					else if (this.layoutMode === 'overlay')
						return this.overlayMenuActive;
				}

				return true;
			},
			closeProcessing() {
				this.$appState.processing = false;
			},
		},
		computed: {
			containerClass() {
				return ['layout-wrapper', {
					'layout-overlay': this.layoutMode === 'overlay',
					'layout-static': this.layoutMode === 'static',
					'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
					'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
					'layout-mobile-sidebar-active': this.mobileMenuActive,
					'p-input-filled': this.$primevue.config.inputStyle === 'filled',
					'p-ripple-disabled': this.$primevue.config.ripple === false,
					'layout-theme-light': this.$appState.theme.startsWith('saga')
				}];
			},
			logo() {
				return (this.$appState.darkTheme) ? "images/logo-white.svg" : "images/logo.svg";
			},
			is_processing() {
				return this.$appState.processing;
			}
		},
		beforeUpdate() {
			if (this.mobileMenuActive)
				this.addClass(document.body, 'body-overflow-hidden');
			else
				this.removeClass(document.body, 'body-overflow-hidden');
		},
		components: {
			'AppTopBar': AppTopBar,
			'AppMenu': AppMenu,
			'AppConfig': AppConfig,
			// 'Login': Login,
			// 'AppFooter': AppFooter,
		}
	}
</script>

<style lang="scss">
	@import './App.scss';

	.close-processing {
		float: right;
		margin-right: -20px;
		fontSize: 1rem;
		float: right;
		margin-left: 20px;
		border-radius: 50%;
		margin-top: auto;
		// padding: 3px;
		// background-color: red;
	}

	.processing-container {
		z-index: 1000000;
		position: absolute;
		top: 80px;
		opacity: 0.95;
		padding: 10px;
		width: 240px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		background-color: var(--primary-color);
		color: #ffffff;
		font-weight: 800;
		// box-shadow:  0px 0px 7px #888888;
	}
</style>
